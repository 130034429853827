<template>
  <v-main>
    <v-container class="px-8" fluid>
      <router-view></router-view>
    </v-container>
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',
  }
</script>